@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';
@import '~@silevis/reactgrid/styles.css';

* {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

#root {
  height: 100%;
}

/* Reactgrid Styles */
.reactgrid-content .rg-pane .rg-cell {
  /* Replace the color as black since the current default color is set to white */
  color: #000000
}

/* Animations */
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-alarm {
  0% {
    box-shadow: 0 0 0 0px rgba(235, 61, 61, 0.9);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 8, 8, 0);
  }
}

#launcher {
  visibility: hidden;
}
